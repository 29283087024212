import React, { useEffect, useRef, useState } from 'react';
import './StepExplainer.css';
import { throttle } from 'lodash';

const StepExplainer = ({ steps }) => {
  const lineRef = useRef(null);
  const containerRef = useRef(null);
  const [lineHeight, setLineHeight] = useState(0);
  const [scrollStarted, setScrollStarted] = useState(false);
  const [isFading, setIsFading] = useState(false);

  useEffect(() => {
    const handleScroll = throttle(() => {
      const containerTop = containerRef.current.getBoundingClientRect().top;
      const containerHeight = containerRef.current.getBoundingClientRect().height;
      const viewportHeight = window.innerHeight;

      const startOffset = 350; // Startversatz in Pixeln
      const fadeOffset = 150; // Offset für das Ausblenden

      if (containerTop < viewportHeight - startOffset && containerTop > 0 && !scrollStarted) {
        setScrollStarted(true);
      }

      if (scrollStarted) {
        const scrollPosition = window.scrollY - (containerRef.current.offsetTop - viewportHeight + startOffset);
        const maxHeight = containerHeight - startOffset + 150;

        if (scrollPosition >= 0 && scrollPosition <= maxHeight) {
          setLineHeight(scrollPosition);
          setIsFading(scrollPosition >= maxHeight - fadeOffset + 200);
        } else if (scrollPosition > maxHeight) {
          setLineHeight(maxHeight);
          setIsFading(true);
        } else {
          setLineHeight(0);
          setIsFading(false);
        }
      }
    }, 10); // Throttle interval in milliseconds

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [scrollStarted]);

  return (
    <div className="step-explainer-container" ref={containerRef}>
      <div className="vertical-line-container">
        <div
          className={`vertical-line ${isFading ? '' : ''}`}
          style={{ height: `${lineHeight}px` }}
          ref={lineRef}
        ></div>
        <img
          src="./img/down-arrow.svg"
          alt="Icon"
          className={`vertical-line-icon ${isFading ? '' : ''}`}
          style={{ top: `${lineHeight}px` }}
        />
      </div>
      <div className="steps-container">
        {steps.map((step, index) => (
          <div key={index} className="step">
            <div className="step-left">
              <h2>{step.step}</h2>
            </div>
            <div className="step-right">
              <h3>{step.title}</h3>
              <div className="step-image-container">
                <img src={step.image} alt={step.title} className="step-image" />
              </div>
              <p>{step.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StepExplainer;

import React, { useState } from 'react';
import './FAQPage.css';

const faqs = [
  { question: 'Are there any additional costs for Guest Pages aside from the 2 Year Subscription fee?', answer: 'No, there are no additional costs for your Guest Pages package. The payment is made every two years.' },
  { question: 'What if I decide within 30 days that I don\'t want Guest Pages?', answer: 'You can cancel without any issues within 30 days and will not be charged.' },
  { question: 'How do I know if Guest Pages is right for my hotel?', answer: 'You can test Guest Pages for 30 days and decide if it\'s right for you during this period.' },
  { question: 'I don\'t have a technical background. Can I still create Guest Pages?', answer: 'Yes, our Guest Pages are very user-friendly. If you need assistance, you can book a one-time setup call with one of our staff members for free.' },
  { question: 'What happens after the two-year subscription ends?', answer: 'You will receive a notification to renew your subscription before it expires to ensure continuous service.' },
  { question: 'Can I customize the information on each Guest Page?', answer: 'Yes, you can easily customize the information on each Guest Page to fit your hotel\'s needs.' },
  { question: 'Is there customer support available if I need help?', answer: 'Yes, our customer support team is available 24/7 to assist you with any issues or questions you may have.' },
];

const FAQPage = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = index => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-page-container">
      <h1 className="faq-title">Frequently Asked Questions</h1>
      <div className="faq-list">
        {faqs.map((faq, index) => (
          <div key={index} className="faq-item">
            <div className="faq-question-container" onClick={() => toggleFAQ(index)}>
              <p className="faq-question">{faq.question}</p>
              <span className="faq-icon">{activeIndex === index ? '-' : '+'}</span>
            </div>
            <div className={`faq-answer ${activeIndex === index ? 'active' : ''}`}>
              <p>{faq.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQPage;

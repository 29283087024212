// src/Admin/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebaseConfig';

const authorizedEmails = ["hardvorevibeandi@gmail.com", "valeriewavy@gmail.com"];

const ProtectedRoute = ({ element }) => {
  const [user, loading, error] = useAuthState(auth);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (user && authorizedEmails.includes(user.email)) {
    return element;
  } else {
    return <Navigate to="/signin" />;
  }
};

export default ProtectedRoute;

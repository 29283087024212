// src/components/PriceList.js
import React from 'react';
import './PriceList.css';
import UrgencyCountdown from './UrgencyCountdown'; // Import the new component

const PriceList = () => {
  const featureTextsLeft = [
    "Up to 10 guest pages",
    "Drag & drop editor",
    "QR codes & custom links",
    "Templates & pre-made designs"
  ];

  const featureTextsRight = [
    "Custom branding suite",
    "1-on-1 setup call",
    "No Hidden Fees",
    "Starting at $25/month"
  ];

  return (
    <div className="price-list-container">
      <div className="price-list-header">
        <div className="price-header-left">
          <h1>Pricing</h1>
          <p>Enjoy a 30-day free trial to explore all the features our platform offers. After the trial, our transparent pricing model offers a monthly payment for just $39, providing a straightforward solution for your Hotel or Bnb.</p>
        </div>
        <div className="price-header-right">
          <h5>30 Day Free Trial</h5>
        </div>
      </div>
      <div className="price-list-content">
        <div className="price-list-item">
          <div className="item-header">
            <div className="item-header-left">
            <div style={{ display: 'flex' }}>
                <h4 style={{ marginRight: '15px', color: 'grey', textDecoration: 'line-through', fontWeight: '500', fontSize: '18px', marginTop: '15px' }}>$69</h4>
                <h4>$39</h4>
                <h4 style={{fontSize: '12px', marginTop: '21px'}}>/month</h4>
              </div>
              <h3>Pay Monthly</h3>
              <p>Experience all the benefits with our monthly plan, giving you flexibility to cancel anytime.</p>
            </div>
            <div className="item-header-right">
              <div style={{ display: 'flex' }}>
                <h4 style={{ marginRight: '15px', color: 'grey', textDecoration: 'line-through', fontWeight: '500', fontSize: '18px', marginTop: '15px' }}>$39</h4>
                <h4>$25</h4>
                <h4 style={{fontSize: '12px', marginTop: '21px'}}>/month</h4>
              </div>
              <h3>Two Year Payment</h3>
              <p>Enjoy BonusClub at a special rate with our two-year license.</p>
            </div>
          </div>
          <div className="item-features">
            <div className="features-left">
              {featureTextsLeft.map((text, i) => (
                <div key={i} className="feature-item">
                  <img src="./img/check_3.svg" alt="Icon" />
                  <p>{text}</p>
                </div>
              ))}
            </div>
            <div className="features-right">
              {featureTextsRight.map((text, i) => (
                <div key={i} className="feature-item">
                  <img src="./img/check_3.svg" alt="Icon" />
                  <p>{text}</p>
                </div>
              ))}
            </div>
          </div>
          <a href="https://bonusclub.link" target="_blank" className="start-trial-button">Start My Free Trial</a>
        </div>
      </div>
    </div>
  );
};

export default PriceList;

// src/components/ExitPopup.js
import React, { useState, useEffect } from 'react';
import ExitPopup1 from './ExitPopup1';
import './ExitPopup.css';

const ExitPopup = () => {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (e.clientY < 100 && e.clientX < 100) {
        setShowPopup(true);
      }
    };

    window.addEventListener('mousemove', handleMouseMove);
    
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <div>
      {showPopup && (
        <div className="exit-popup-overlay">
          <ExitPopup1 closePopup={closePopup} />
        </div>
      )}
    </div>
  );
};

export default ExitPopup;

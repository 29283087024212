import React from 'react';
import './TopHeader1.css';

const TopHeader1 = ({ title, subtitle, buttonText, images, buttonLink }) => {
  return (
    <div className="top-header-container">
      <h5>Now with AI Superpowers</h5>
      <h1 className="top-header-title">{title}</h1>
      <p className="top-header-subtitle">{subtitle}</p>
      <div className="header-button">
      <div className="button-wrapper">
      <div className="button-container-1">
        <a href={buttonLink} target="_blank" className="top-header-button">
          <span className="button-icon">⭐️</span> {/* Hier das Icon */}
          {buttonText}
          <span className="light-streak-2"></span> {/* Der Lichtstreifen */}
          <span className="light-streak-1"></span> {/* Der Lichtstreifen */}
        </a>
      </div>
      </div>
      </div>

      {images.map((image, index) => (
        <img key={index} src={image.src} alt={image.alt} className={`relative-image image-${index + 1}`} />
      ))}
    </div>
  );
};

export default TopHeader1;

import React, { useEffect, useState } from 'react';
import Countdown from './Countdown';
import './Navigation1.css';

const Navigation2 = ({actionLink, actionLinkName}) => {
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const controlNavbar = () => {
    if (window.scrollY > lastScrollY) {
      // Scroll down
      setIsVisible(false);
    } else {
      // Scroll up
      setIsVisible(true);
    }
    setLastScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', controlNavbar);
    return () => {
      window.removeEventListener('scroll', controlNavbar);
    };
  }, [lastScrollY]);

  return (
    <div>
      <header className={`navigation-header ${isVisible ? 'visible' : 'hidden'}`}>
        <div className="navigation-menu">
            <Countdown />
        </div>
      </header>
      <img src="../img/bonusclub_logo.webp" alt="Logo" className={`navigation-logo-fixed ${isVisible ? 'visible' : 'hidden'}`} />
      <img src="../img/icon.png" alt="Icon" className={`navigation-icon-fixed ${isVisible ? 'hidden' : 'visible'}`} />
      <div className="fixed-button-container">
        <button className="get-started-button">
        <a style={{color: 'black'}} href={actionLink} target="_blank" className="learn-more-link">{actionLinkName}</a>
        </button>
        <a href="https://bonusclub.link"  target="_blank" className="learn-more-link">Log In</a>
      </div>
    </div>
  );
};

export default Navigation2;

// src/components/BenefitComponent1.js
import React from 'react';
import './BenefitComponent1.css';

const BenefitComponent1 = ({ imageSrc, iconSrc, headerText, absoluteText, title, description, buttonText, buttonLink }) => {
  return (
    <div className="benefit-wrapper">
      <div className="image-wrapper">
        <img src={imageSrc} alt="Example" className="benefit-image" />
      </div>
      <div className="benefit-component">
        <div className="benefit-container">
          <div className="benefit-header">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={iconSrc} alt="Icon" className="icon" />
              <p className="benefit-header-text">{headerText}</p>
            </div>
            <p className="absolute-text">{absoluteText}</p>
          </div>
          <img src={imageSrc} alt="Example" className="benefit-image-inside" />
          <h2 className="benefit-title">{title}</h2>
          <p className="benefit-description">{description}</p>
          <a href={buttonLink} target="_blank" className="benefit-wrapper-button">{buttonText}</a>
        </div>
      </div>
    </div>
  );
};

export default BenefitComponent1;

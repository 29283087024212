// src/components/OfferList.js
import React from 'react';
import Offer from './Offer';
import './OfferList.css';

const offers = [
  {
    title: 'GUEST PAGES PRO',
    description: 'Provide every guest with a personalized experience by creating up to 3 different Guest Pages. Each page can include essential details like room amenities, local attractions, and special offers, ensuring guests have all the information they need at their fingertips.',
    image: './img/Bonusclub_Pages.webp',
    worth: 'Worth $599',
    showWorth: true,
  },
  {
    title: 'DRAG & DROP EDITOR',
    description: 'The platform features an intuitive Drag and Drop Editor, making it quick and easy to create new Guest Pages. This user-friendly tool allows you to customize pages effortlessly without any technical expertise.',
    image: './img/Bonusclub_Laptop.webp',
    worth: 'Worth $299',
    showWorth: true,
  },

  {
    title: 'DESIGNER TEMPLATES',
    description: 'Choose from a variety of templates and pre-made designs that can be imported with a single click. This feature allows you to create and customize Guest Pages in less than five minutes, ensuring quick and efficient setup.',
    image: './img/Bonusclub_Templates.webp',
    worth: 'Worth $249',
    showWorth: true,
  },
  {
    title: 'CUSTOM BRANDING SUITE',
    description: 'Import your logos, select your colors, and choose fonts to match your branding perfectly. This feature allows you to create a seamless and professional user experience that aligns with your hotel`s identity.    ',
    image: './img/Grafik_Mockups_Style.webp',
    worth: 'Worth $199',
    showWorth: true,
  },
  {
    title: 'AUTOMATIC QR CODES',
    description: 'For each Guest Page, a QR code is automatically generated, and you can create your own custom links. This ensures easy access for guests, allowing them to quickly retrieve all necessary information with a simple scan or click.',
    image: './img/Bonusclub_QR.webp',
    worth: 'Worth $99',
    showWorth: true,
  },
  {
    title: '1-ON-1 SETUP CALL',
    description: 'Get a personalized setup with a one-on-one video call from one of our experts. During this call, you can create your Guest Page together, ensuring everything is perfectly tailored to your needs.',
    image: './img/Bonusclub_Video.webp',
    worth: 'Limited Bonus worth $199',
    showWorth: true,
  },
];

const OfferList = () => {
  return (
    <div className="offer-list">
        <h2>What you will get</h2>
      {offers.map((offer, index) => (
        <Offer
          key={index}
          title={offer.title}
          description={offer.description}
          image={offer.image}
          worth={offer.worth}
          showWorth={offer.showWorth}
        />
      ))}
    </div>
  );
};

export default OfferList;

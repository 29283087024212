// src/components/BenefitComponent2.js
import React from 'react';
import './BenefitComponent2.css';

const BenefitComponent2 = ({ imageSrc, iconSrc, headerText, absoluteText, title, description, buttonText, buttonLink }) => {
  return (
    <div className="benefit2-wrapper">
      <div className="benefit2-component">
        <div className="benefit2-container">
          <div className="header2">
          <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={iconSrc} alt="Icon" className="icon2" />
              <p className="header2-text">{headerText}</p>
            </div>
            <p className="absolute2-text">{absoluteText}</p>
          </div>
          <img src={imageSrc} alt="Example" className="benefit-image-inside" />
          <h2 className="benefit2-title">{title}</h2>
          <p className="benefit2-description">{description}</p>
          <a href={buttonLink} target="_blank" className="benefit-wrapper-button">{buttonText}</a>
        </div>
      </div>
      <div className="image2-wrapper">
        <img src={imageSrc} alt="Example" className="benefit2-image" />
      </div>
    </div>
  );
};

export default BenefitComponent2;

// src/components/SetSpecialOffer.js
import React, { useState } from 'react';
import { database, ref, set } from '../firebaseConfig';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './SpecialOffer.css';

const SetSpecialOffer = () => {
  const [startDate, setStartDate] = useState(new Date());

  const handleDateChange = (date) => {
    setStartDate(date);
  };

  const saveDateToDatabase = async () => {
    const endDateRef = ref(database, 'SpecialOffer/endDate');
    await set(endDateRef, startDate.toISOString());
    alert('Special offer end date saved successfully!');
  };

  return (
    <div className="set-special-offer">
      <h2>Set Special Offer End Date</h2>
      <DatePicker selected={startDate} onChange={handleDateChange} showTimeSelect dateFormat="Pp" />
      <button onClick={saveDateToDatabase}>Save Date</button>
    </div>
  );
};

export default SetSpecialOffer;

import React from 'react'
import { Link } from 'react-router-dom'

function Imprint() {
  return (
    <div>
      <div className="terms-of-use">
        <Link to="/">
          <img style={{filter: 'invert(1)'}} src="./img/bonusclub_logo.webp" alt="" />
        </Link>
        <h1>Imprint</h1>
        <p>Bonusclub.io</p>
        <p>Reforma 222, Torre 3</p>
        <p>Mexico City, Cuauhtémoc, Mexico</p>
        <p>Email: info@bonusclub.io</p>
        <p>Telephone: +52 55 3033 1201</p>
        <p>Whatsapp: +52 55 3033 1201</p>

        <h1>Contact Information</h1>
        <p>Telephone: +52 55 3033 1201</p>
        <p>Email: info@bonusclub.io</p>

        <h1>Disclaimer</h1>
        <p>The information provided on this website is for general informational purposes only. All information on the site is provided in good faith, however we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the site.</p>

        <h1>External Links Disclaimer</h1>
        <p>The site may contain (or you may be sent through the site) links to other websites or content belonging to or originating from third parties or links to websites and features in banners or other advertising. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability, or completeness by us. We do not warrant, endorse, guarantee, or assume responsibility for the accuracy or reliability of any information offered by third-party websites linked through the site or any website or feature linked in any banner or other advertising. We will not be a party to or in any way be responsible for monitoring any transaction between you and third-party providers of products or services.</p>

        <h1>Professional Disclaimer</h1>
        <p>The site cannot and does not contain [medical/legal/fitness/health] advice. The [medical/legal/fitness/health] information is provided for general informational and educational purposes only and is not a substitute for professional advice. Accordingly, before taking any actions based upon such information, we encourage you to consult with the appropriate professionals. We do not provide any kind of [medical/legal/fitness/health] advice. The use or reliance of any information contained on this site is solely at your own risk.</p>


        <h1>Errors and Omissions Disclaimer</h1>
        <p>While we have made every attempt to ensure that the information contained in this site has been obtained from reliable sources, Bonus Club is not responsible for any errors or omissions or for the results obtained from the use of this information. All information in this site is provided "as is," with no guarantee of completeness, accuracy, timeliness, or of the results obtained from the use of this information, and without warranty of any kind, express or implied, including, but not limited to warranties of performance, merchantability, and fitness for a particular purpose.</p>
        <p>In no event will Bonus Club, its related partnerships or corporations, or the partners, agents, or employees thereof be liable to you or anyone else for any decision made or action taken in reliance on the information in this site or for any consequential, special, or similar damages, even if advised of the possibility of such damages.</p>

        <h1>Logos and Trademarks Disclaimer</h1>
        <p>All logos and trademarks of third parties referenced on www.bonusclub.io are the trademarks and logos of their respective owners. Any inclusion of such trademarks or logos does not imply or constitute any approval, endorsement, or sponsorship of Bonus Club by such owners.</p>

        <h1>Contact Us</h1>
        <p>If you have any questions about this Imprint, please contact us:</p>
        <ul>
          <li>By email: info@bonusclub.io</li>
        </ul>
      </div>
    </div>
  )
}

export default Imprint

import React, { useState, useRef } from 'react';
import './HeaderVideo.css';

const HeaderVideo = () => {
    const [isPlaying, setIsPlaying] = useState(false);
    const videoRef = useRef(null);

    const togglePlayPause = () => {
        const video = videoRef.current;
        if (video.paused || video.ended) {
            video.play();
            setIsPlaying(true);
        } else {
            video.pause();
            setIsPlaying(false);
        }
    };

    return (
        <div className="video-wrapper-2">
            <h2>A Message from our Co-Founder</h2>
            <p>A short introduction on Bonusclub presented by our Co-Founder Julian.</p>
            <div className="video-container-2">
                <video
                    ref={videoRef}
                    controls
                    onMouseEnter={() => videoRef.current.controls = true}
                    onMouseLeave={() => videoRef.current.controls = false}
                    onEnded={() => setIsPlaying(false)}
                    onError={(e) => console.log('Error loading video', e)}
                    preload="auto"
                    >
                    <source src="./videos/Header_Video.mp4" type="video/mp4" />
                    Sorry, your browser doesn't support embedded videos.
                </video>
                {!isPlaying && (
                    <img
                        src="./img/header_video_placeholder.webp"
                        alt="Video placeholder"
                        className="header-video-placeholder"
                        onClick={togglePlayPause}
                        style={{ display: 'block' }}
                    />
                )}
                {!isPlaying && (
                    <button
                        className="play-pause-button"
                        onClick={togglePlayPause}
                        style={{ backgroundImage: `url(./img/play.svg)`, display: 'block' }}
                    />
                )}
            </div>
        </div>
    );
};

export default HeaderVideo;

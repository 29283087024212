// src/components/BreakBar.js
import React from 'react';
import Marquee from 'react-fast-marquee';
import './BreakBar.css';

const BreakBar = () => {
  return (
    <div className="break-bar">
      <Marquee gradient={false} speed={50}>
        <img src="./references/referenz_1.webp" alt="Referenz 1" className="logo" />
        <img src="./references/referenz_2.webp" alt="Referenz 2" className="logo" />
        <img src="./references/referenz_3.webp" alt="Referenz 3" className="logo" />
        <img src="./references/referenz_4.webp" alt="Referenz 4" className="logo" />
        <img src="./references/referenz_5.webp" alt="Referenz 5" className="logo" />
        <img src="./references/referenz_6.webp" alt="Referenz 6" className="logo" />
        <img src="./references/referenz_7.webp" alt="Referenz 7" className="logo" />
        <img src="./references/referenz_8.webp" alt="Referenz 8" className="logo" />
        {/* Duplizieren für den nahtlosen Effekt */}
        <img src="./references/referenz_1.webp" alt="Referenz 1" className="logo" />
        <img src="./references/referenz_2.webp" alt="Referenz 2" className="logo" />
        <img src="./references/referenz_3.webp" alt="Referenz 3" className="logo" />
        <img src="./references/referenz_4.webp" alt="Referenz 4" className="logo" />
        <img src="./references/referenz_5.webp" alt="Referenz 5" className="logo" />
        <img src="./references/referenz_6.webp" alt="Referenz 6" className="logo" />
        <img src="./references/referenz_7.webp" alt="Referenz 7" className="logo" />
        <img src="./references/referenz_8.webp" alt="Referenz 8" className="logo" />
      </Marquee>
    </div>
  );
};

export default BreakBar;

// src/pages/ActionPage.js
import React, { useState, useEffect, useRef } from 'react';
import './ActionPage.css';

const ActionPage = () => {
  const [selectedImage, setSelectedImage] = useState('./img/Boutique_Mockup.webp');
  const [activeButton, setActiveButton] = useState('./img/Boutique_Mockup.webp');
  const scrollableImageRef = useRef(null);

  const handleButtonClick = (imagePath) => {
    setSelectedImage(imagePath);
    setActiveButton(imagePath);
  };

  useEffect(() => {
    // Scroll to the top of the scrollable image container when the selected image changes
    if (scrollableImageRef.current) {
      scrollableImageRef.current.scrollTop = 0;
    }
  }, [selectedImage]);

  return (
    <div className="action-page-container">
      <div className="action-content">
        <div className="action-text-content">
          <div className="button-container">
            <button
              className={`action-button ${activeButton === './img/Boutique_Mockup.webp' ? 'active' : ''}`}
              onClick={() => handleButtonClick('./img/Boutique_Mockup.webp')}
            >
              Boutique
            </button>
            <button
              className={`action-button ${activeButton === './img/Luxurious_Mockup.webp' ? 'active' : ''}`}
              onClick={() => handleButtonClick('./img/Luxurious_Mockup.webp')}
            >
              Luxurious
            </button>
            <button
              className={`action-button ${activeButton === './img/Beachy_Mockup.jpg' ? 'active' : ''}`}
              onClick={() => handleButtonClick('./img/Beachy_Mockup.jpg')}
            >
              Beach
            </button>
            <button
              className={`action-button ${activeButton === './img/Checkin_Guide.jpg' ? 'active' : ''}`}
              onClick={() => handleButtonClick('./img/Checkin_Guide.jpg')}
            >
              Check-In
            </button>
          </div>
          <p className="action-small-text">Become a Creator</p>
          <h1 className="action-title">See A Guest Page In Action</h1>
          <p className="action-description">See how easy it is to provide your guests with everything they need for a comfortable and enjoyable stay.</p>
        </div>
        <div className="image-content">
          <img src="./img/handytest.png" alt="Sample" className="sample-image" />
          <div className="overlay-container">
            <div className="scrollable-image" ref={scrollableImageRef}>
              <img src={selectedImage} alt="Scrollable" className="overlay-image" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionPage;

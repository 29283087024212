// src/components/ExitPopup1.js
import React from 'react';
import { Link } from 'react-router-dom';
import './ExitPopup1.css';

const ExitPopup1 = ({ closePopup }) => {
  return (
    <div className="exit-popup-container">
      <button className="exit-popup-close-btn" onClick={closePopup}>
          <img src="./img/x.svg" alt="" />
      </button>
      <img src="./img/header_video_placeholder.webp" alt="Popup" className="popup-image" />
      <h2 className="popup-heading">Need More Bookings?</h2>
      <div className="popup-benefits">
        <div className="benefit-item">
          <img src="./img/check_3.svg" alt="Benefit 1" className="benefit-image-popup" />
          <p>22%* More Reviews</p>
        </div>
        <div className="benefit-item">
          <img src="./img/check_3.svg" alt="Benefit 2" className="benefit-image-popup"/>
          <p>Better Guest Reputation</p>
        </div>
        <div className="benefit-item">
          <img src="./img/check_3.svg" alt="Benefit 3" className="benefit-image-popup" />
          <p>More Bookings</p>
        </div>
        <div className="benefit-item">
          <img src="./img/check_3.svg" alt="Benefit 4" className="benefit-image-popup" />
          <p>Save Time & Money</p>
        </div>
      </div>
      <a style={{textDecoration: 'none'}} href="https://calendly.com/bonusclub/free-bonusclub-strategy-call-30-min" target="_blank" className="popup-button">Free 30 Minute Strategy Call</a>
      <img src="./img/down.svg" alt="Circle" className="popup-circle-image" />
      <p className="popup-small-text">*According to Customer Surveys</p>
    </div>
  );
};

export default ExitPopup1;

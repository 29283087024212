// src/components/AboutProduct.js
import React from 'react';
import './AboutProduct.css';

const AboutProduct = ({ subtitle, title, imageSrc, description }) => {
  return (
    <div className="about-product-container">
      <p className="about-product-subtitle">{subtitle}</p>
      <h2 className="about-product-title">{title}</h2>
      <img src={imageSrc} alt="Product" className="about-product-image" />
      <p className="about-product-description">{description}</p>
    </div>
  );
};

export default AboutProduct;

// src/components/StripeFlow.js
import React, { useEffect, useRef, useState } from 'react';
import './StripeFlow.css';

const StripeFlow = () => {
  const stripeRef = useRef(null);
  const [stripePosition, setStripePosition] = useState(0);
  const [scrollStarted, setScrollStarted] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const containerTop = stripeRef.current.getBoundingClientRect().top;
      const viewportHeight = window.innerHeight;

      if (containerTop < viewportHeight && containerTop > 0 && !scrollStarted) {
        setScrollStarted(true);
      }

      if (scrollStarted) {
        const scrollPosition = window.scrollY - (stripeRef.current.offsetTop - viewportHeight);
        if (scrollPosition >= 0 && scrollPosition <= 300) {
          setStripePosition(scrollPosition);
        } else if (scrollPosition > 300) {
          setStripePosition(300);
        } else {
          setStripePosition(0);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [scrollStarted]);

  return (
    <div className="stripe-flow-container" ref={stripeRef}>
      <div className="stripe-flow" style={{ height: `${stripePosition}px` }}>
        <img
          src="./img/bonusclub_icon.svg"
          alt="Icon"
          className="stripe-flow-icon"
          style={{ top: `${stripePosition}px` }}
        />
      </div>
    </div>
  );
};

export default StripeFlow;

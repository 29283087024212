import React, { useState, useRef, useEffect } from 'react';
import './TestimonialVideo.css';

const VideoCard = ({ placeholder, videoSrc, playIcon, name, type, countryIcon, currentlyPlaying, setCurrentlyPlaying }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const handlePlay = async () => {
    if (currentlyPlaying && currentlyPlaying !== videoRef.current) {
      currentlyPlaying.pause();
      currentlyPlaying.currentTime = 0;
    }
    if (videoRef.current) {
      try {
        await videoRef.current.play();
        setIsPlaying(true);
        setCurrentlyPlaying(videoRef.current);
      } catch (error) {
        console.error("Failed to play the video:", error);
      }
    }
  };

  const handlePause = () => {
    if (isPlaying && videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
      setIsPlaying(false);
      setCurrentlyPlaying(null);
    }
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.addEventListener('pause', () => setIsPlaying(false));
    }
    return () => {
      if (videoElement) {
        videoElement.removeEventListener('pause', () => setIsPlaying(false));
      }
    };
  }, []);

  return (
    <div className={`video-card ${isPlaying ? 'playing' : ''}`}>
      <div className="video-container">
        <div className="video-placeholder" onClick={handlePlay}>
          <img src={placeholder} alt="Video placeholder" />
          <img style={{ width: '40px', height: '40px', filter: 'invert(1)' }} className="play-icon" src={playIcon} alt="Play button" />
        </div>
        <video ref={videoRef} controls className="video-element" preload="metadata">
          <source src={videoSrc} type="video/mp4" />
        </video>
        <button className="close-button" onClick={handlePause}>✕</button>
      </div>
      <div className="video-info">
        <div>
        <span className="name">{name}</span>
        <img src={countryIcon} alt={`${name}'s country`} className="country-icon" />
        </div>
    
        <span className="type">{type}</span>
      </div>
    </div>
  );
};

const TestimonialVideo = () => {
  const [currentlyPlaying, setCurrentlyPlaying] = useState(null);
  const playIcon = './img/play.svg'; // Single play icon for all videos

  const videos = [
    { placeholder: './img/Rachel.webp', videoSrc: './videos/Rachel.mov', name: 'Rachel', type: 'Hotel', countryIcon: './img/australia.svg' },
    { placeholder: './img/Tim.webp', videoSrc: './videos/Tim.mov', name: 'Tim', type: 'Hotel', countryIcon: './img/usa.svg' },
    { placeholder: './img/Lisa.webp', videoSrc: './videos/Lisa.mov', name: 'Lisa', type: 'Hotel', countryIcon: './img/canada.svg' },
    { placeholder: './img/Scott.webp', videoSrc: './videos/Scott.mov', name: 'Scott', type: 'BnB', countryIcon: './img/usa.svg' },
    { placeholder: './img/Lucia.webp', videoSrc: './videos/Lucia.mov', name: 'Lucia', type: 'Hotel', countryIcon: './img/argentina.svg' },
    { placeholder: './img/Jake.webp', videoSrc: './videos/Jake.mov', name: 'Jake', type: 'Hotel', countryIcon: './img/australia.svg' },
    { placeholder: './img/Harley.webp', videoSrc: './videos/Harley.mov', name: 'Harley', type: 'BnB', countryIcon: './img/uk.svg' },
    { placeholder: './img/MJ_Wolf.webp', videoSrc: './videos/MJ_Wolf.mov', name: 'Michael', type: 'Hotel', countryIcon: './img/usa.svg' },
    { placeholder: './img/Valeria.webp', videoSrc: './videos/Valeria.mov', name: 'Valeria', type: 'Hotel', countryIcon: './img/argentina.svg' },
    { placeholder: './img/Victoria.webp', videoSrc: './videos/Victoria.mov', name: 'Victoria', type: 'Guest', countryIcon: './img/usa.svg' },
    { placeholder: './img/Josh.webp', videoSrc: './videos/Josh.mov', name: 'Josh', type: 'Hotel', countryIcon: './img/usa.svg' },
     { placeholder: './img/Ashlyn.webp', videoSrc: './videos/Ashlyn.mov', name: 'Ashlyn', type: 'Hotel', countryIcon: './img/usa.svg' },
  ];

  return (
    <div className="testimonials">
            <h2>Happy Bonusclub Owners</h2>
            <p>Listen what others have to say about Bonusclub online</p>
    <div className="testimonial-video">
      {videos.map((video, index) => (
        <VideoCard
          key={index}
          placeholder={video.placeholder}
          videoSrc={video.videoSrc}
          playIcon={playIcon}
          name={video.name}
          type={video.type}
          countryIcon={video.countryIcon}
          currentlyPlaying={currentlyPlaying}
          setCurrentlyPlaying={setCurrentlyPlaying}
        />
      ))}
    </div>
    </div>
  );
};

export default TestimonialVideo;

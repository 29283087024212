// src/Landingpage/Countdown.js
import React, { useEffect, useState } from 'react';
import { database, ref, get } from '../firebaseConfig';
import './Countdown.css';

const Countdown = () => {
  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [isValidDate, setIsValidDate] = useState(false);

  useEffect(() => {
    const fetchEndDate = async () => {
      const endDateRef = ref(database, 'SpecialOffer/endDate');
      const snapshot = await get(endDateRef);
      if (snapshot.exists()) {
        const endDate = new Date(snapshot.val());
        if (endDate > new Date()) {
          startCountdown(endDate);
          setIsValidDate(true);
        }
      } else {
        console.error('No end date found in database.');
      }
    };

    const startCountdown = (endDate) => {
      const updateCountdown = () => {
        const now = new Date();
        const distance = endDate - now;

        if (distance > 0) {
          const days = Math.floor(distance / (1000 * 60 * 60 * 24));
          const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((distance % (1000 * 60)) / 1000);
          setCountdown({ days, hours, minutes, seconds });
        } else {
          setIsValidDate(false);
        }
      };

      updateCountdown();
      const interval = setInterval(updateCountdown, 1000);

      return () => clearInterval(interval);
    };

    fetchEndDate();
  }, []);

  if (!isValidDate) {
    return null;
  }

  return (
    <div className="countdown-container">
      <div className="countdown">
        <div className="time-box">{countdown.days}<span className="label">days</span></div>
        <div className="time-box">{countdown.hours}<span className="label">hours</span></div>
        <div className="time-box">{countdown.minutes}<span className="label">minutes</span></div>
        <div className="time-box">{countdown.seconds}<span className="label">seconds</span></div>
      </div>
      <div className="limited-offer">
        <img  src="./img/bell.webp" alt="Bell icon" />
        <a target="_blank" style={{textDecoration: 'none', color: 'black'}} href="https://bonusclub.io/book-call-1">FREE Strategy Call</a>
        <a className="free-strategy-button" href="https://bonusclub.io/book-call-1" target="_blank">
          <img src="./img/down.svg" alt="" />
        </a>
      </div>
    </div>
  );
};

export default Countdown;

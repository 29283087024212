// src/Admin/TeamDashboard.js
import React from 'react';
import SetSpecialOffer from '../Landingpage/SpecialOffer';

const TeamDashboard = () => {
  return (
    <div>
      <h1>Team Dashboard</h1>
      <SetSpecialOffer />
    </div>
  );
};

export default TeamDashboard;

// src/Admin/SignIn.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, signInWithPopup, GoogleAuthProvider } from '../firebaseConfig';
import './SignIn.css';

const SignIn = () => {
  const navigate = useNavigate();

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, new GoogleAuthProvider());
      console.log('User signed in:', result.user);
      navigate('/team-dashboard'); // Weiterleitung zum Team-Dashboard
    } catch (error) {
      console.error('Error signing in with Google:', error);
    }
  };

  return (
    <div className="sign-in-container">
      <h2>Sign In</h2>
      <button onClick={handleGoogleSignIn} className="google-sign-in-button">
        Sign in with Google
      </button>
    </div>
  );
};

export default SignIn;

import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-column">
          <img src="./img/bonusclub_logo.webp" alt="Bonus Club Logo" className="footer-logo" />
          <p>Crafting share-worthy travel stories in bite-sized visual masterpieces.</p>
          <div className="footer-social">
            <a href="https://instagram.com/bonusclub.hotels" target="_blank"> <img src="./img/instagram.svg" alt="Instagram" /></a>
            <a href="#"><img src="./img/whatsapp.svg" alt="Whatsapp" /></a>
          </div>
        </div>
        <div className="footer-column">
          <h3>Information</h3>
          <div className="legal-list">
            <Link to="/terms">Terms of Use</Link>
           <Link to="/privacy">Privacy Policy</Link>
           <Link to="/imprint">Imprint</Link>
          </div>
        </div>
        <div className="footer-column">
          <h3>Contact Us</h3>
          <p>Reforma 222 Torre 3,<br/> 06600, Juarez, Mexico City</p>
          <p><a href="tel:+5530331201">WhatsApp: +5530331201</a></p>
          <p><a href="mailto:office@bonusclub.link">office@bonusclub.io</a></p>
        </div>
        <div className="footer-column">
          <h3>Looking for the login?</h3>
          <a href="https://bonusclub.link" className="cta-button-2">Log in here</a>

        </div>
      </div>
      <div className="footer-bottom">
        <p>© 2024 | All rights reserved by Bonus Club</p>
        <p>Capture, Share, Celebrate.</p>
      </div>
    </footer>
  );
};

export default Footer;

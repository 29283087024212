// src/components/VideoCTA.js
import React, { useState, useEffect } from 'react';
import './VideoCTA.css';

const VideoCTA = () => {
  const [visible, setVisible] = useState(false);
  const [ctaVisible, setCtaVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(true);
    }, 20000); // 20 seconds delay

    return () => clearTimeout(timer);
  }, []);

  const handleCTAClick = () => {
    window.open('https://calendly.com/bonusclub/free-bonusclub-strategy-call-30-min', '_blank');
  };

  return (
    <div>
      {visible && (
        ctaVisible ? (
          <div className="video-cta" onClick={handleCTAClick}>
            <button className="close-btn" onClick={(e) => { e.stopPropagation(); setCtaVisible(false); }}>
              <img src="./img/x.svg" alt="Close" />
            </button>
            <button className="pulse-btn" onClick={(e) => e.stopPropagation()}>
            </button>
            <div className="image-container">
              <img src="./img/hey_2.webp" alt="Profile" />
              <div className="gradient-overlay"></div>
            </div>
            <div className="cta-text">
              Live Demo?
            </div>
          </div>
        ) : (
          <div className="video-toggle-button" onClick={() => setCtaVisible(true)}>
            <img src="./img/hey_2.webp" alt="Toggle" />
          </div>
        )
      )}
    </div>
  );
};

export default VideoCTA;

import React from 'react';
import './CalendlyPage.css';

const CalendlyPage = () => {
  return (
    <div className="calendly-page-container">
      <h1 className="calendly-page-title">Book Free Strategy Call!</h1>
      <p className="page-description">Learn the secrets to happier guests and discover how to leverage booking algorithms to your advantage with our expert tips.</p>
      <div className="calendly-bullet-points">
        <p style={{marginTop: '15px'}}>What you get:</p>
        <div style={{marginTop: '0px'}} className="calendly-bullet-point">
          <img src="./img/tick.svg" alt="Check Icon" className="calendly-bullet-icon" />
          <p>15-30 minutes Zoom meeting</p>
        </div>
        <div className="calendly-bullet-point">
          <img src="./img/tick.svg" alt="Check Icon" className="calendly-bullet-icon" />
          <p>Evaluate your current situation</p>
        </div>
        <div className="calendly-bullet-point">
          <img src="./img/tick.svg" alt="Check Icon" className="calendly-bullet-icon" />
          <p><strong></strong> Bonusclub demo with an expert</p>
        </div>
      </div>

      <div className="calendly-embed">
        <iframe
          src="https://calendly.com/bonusclub/free-bonusclub-strategy-call-30-min"
          width="100%"
          height="700"
          frameBorder="0"
          title="Calendly Widget"
        ></iframe>
      </div>
    </div>
  );
};

export default CalendlyPage;

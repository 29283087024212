import React from 'react';
import Navigation2 from './Navigation2';
import TopHeader1 from './TopHeader1';
import BreakBar from './BreakBar';
import AboutProduct from './AboutProduct';
import './LandingPage1.css';
import BenefitComponent1 from './BenefitComponent1';
import BenefitComponent2 from './BenefitComponent2';
import StepExplainer from './StepExplainer';
import OfferList from './OfferList';
import ReviewGallery from './ReviewGallery';
import ActionPage from './ActionPage';
import CalendlyPage from './CalendlyPage';
import FAQPage from './FAQPage';
import StripeFlow from './StripeFlow';
import PriceList from './PriceList';
import Footer from './Footer';
import VideoCTA from './VideoCTA';
import ExitPopup from './ExitPopup';
import TestimonialVideo from './TestimonialVideo';
import HeaderVideo from './HeaderVideo';

const steps = [
  {
    step: 'Step 1',
    title: 'Create Guest Page',
    image: './img/Bonusclub_Ipad.webp',
    description: 'Quickly set up a personalized Guest Page with all the necessary details, including room amenities, restaurant tips, local activities, and opportunities for upsells, to ensure your guests have an unforgettable experience.',
  },
  {
    step: 'Step 2',
    title: 'Share Link with Guests',
    image: './img/Bonusclub_Customer.webp',
    description: 'Easily share the Guest Page link or QR code with your guests, giving them instant access to all the information they need, from room details to local recommendations, right at their fingertips.',
  },
  {
    step: 'Step 3',
    title: 'Analyze & Improve',
    image: './img/Grafik_Mockups_Statistic_2.webp',
    description: 'Use detailed statistics and guest feedback from the Guest Pages to continuously enhance your services, increase guest satisfaction, and boost your hotel`s efficiency and revenue.',
  },
];

const LandingPageBnb3CTA = () => {
  return (
    <div className="landing-page">
      <Navigation2 
      actionLink="https://bonusclub.io/book-call-1"
      actionLinkName="Free Strategy Call"
      />
      <TopHeader1
        title="Host your Airbnb like a Pro."
        subtitle="Transform your Airbnb and increase your bookings using our personalized digital guest pages."
        buttonText="FREE STRATEGY CALL"
        buttonLink="https://bonusclub.io/book-call-1"
        images={[
          { src: './img/Binocular.webp', alt: 'Example 1' },
          { src: './img/Plane.webp', alt: 'Example 2' },
          { src: './img/Coconut.webp', alt: 'Example 3' },
        ]}
      />
      <BreakBar />
      <StripeFlow />
      <HeaderVideo />
      <AboutProduct
        subtitle="Here is what you get"
        title="What is a Guest Page?"
        imageSrc="./img/Bonusclub_Phone.webp"
        description="Guest Pages are small websites that can be created within minutes to provide guests with valuable information about their stay. These pages can include details such as Wi-Fi passwords, house rules, check-in information, and more, reducing the need for direct communication with the host."
      />
      <BenefitComponent1
        imageSrc="./img/Bonusclub_Problem.webp"
        iconSrc="./img/eye.svg"
        headerText="Instant Help"
        absoluteText="Guest Service"
        title="Let your Guests Check-In on Autopilot."
        description="Guest Pages give your guests all the information they need, like Wi-Fi passwords and check-in details. This reduces the number of common questions and frees up your staff to focus on other important tasks. By using Guest Pages, you make your guests' stay smoother and your team's work easier. "
        buttonText="Free Stragegy Call"
        buttonLink="https://bonusclub.io/book-call-1"
      />
      <BenefitComponent2
        imageSrc="./img/bonusclub_hipster_2.webp"
        iconSrc="./img/sick.svg"
        headerText="Less Frustration"
        absoluteText="Guest Support"
        title="Boost your Airbnb, even when you sleep."
        description="Reduce guest frustration by providing all the information guests need, anytime they need it. Even when your tean is unavailable, guests can access your guest page. This ensures problems are solved before they even arise, making for a smoother and more enjoyable stay for everyone."
        buttonText="BOOK YOUR CALL"
        buttonLink="https://bonusclub.io/book-call-1"
      />
      <BenefitComponent1
        imageSrc="./img/Bonusclub_Reviews.webp"
        iconSrc="./img/wand.svg"
        headerText="Better Reviews"
        absoluteText="Guest Engagement"
        title="Happy Guests leave better Reviews."
        description="Happier guests lead to better reviews, and Bonus Club's Guest Pages help make that happen. By providing all the information guests need at their fingertips, you enhance their stay and reduce potential frustrations. When guests have a smooth and enjoyable experience, they're more likely to leave positive reviews."
        buttonText="WE CREATE YOUR PAGE"
        buttonLink="https://bonusclub.io/book-call-1"
      />
      <StepExplainer steps={steps} />
      <OfferList />
      <StripeFlow />
      <PriceList />
      <TestimonialVideo />
      <ActionPage />
      <CalendlyPage />
      <FAQPage />
      <Footer />
      <VideoCTA />
      <ExitPopup />
    </div>
  );
};

export default LandingPageBnb3CTA;

import React, { useEffect } from 'react';
import Navigation2 from './Navigation2';
import TopHeader1 from './TopHeader1';
import BreakBar from './BreakBar';
import AboutProduct from './AboutProduct';
import './LandingPage1.css';
import BenefitComponent1 from './BenefitComponent1';
import BenefitComponent2 from './BenefitComponent2';
import StepExplainer from './StepExplainer';
import OfferList from './OfferList';
import ReviewGallery from './ReviewGallery';
import ActionPage from './ActionPage';
import CalendlyPage from './CalendlyPage';
import FAQPage from './FAQPage';
import StripeFlow from './StripeFlow';
import PriceList from './PriceList';
import Footer from './Footer';
import VideoCTA from './VideoCTA';
import ExitPopup from './ExitPopup';
import TestimonialVideo from './TestimonialVideo';
import HeaderVideo from './HeaderVideo';

const steps = [
  {
    step: 'Step 1',
    title: 'Create Guest Page',
    image: './img/Bonusclub_Ipad.webp',
    description: 'Quickly set up a personalized Guest Page with all the necessary details, including room amenities, restaurant information, local activities, and opportunities for upsells, to ensure your guests have a seamless experience.',
  },
  {
    step: 'Step 2',
    title: 'Share Link with Guests',
    image: './img/Bonusclub_Customer.webp',
    description: 'Easily share the Guest Page link or QR code with your guests, giving them instant access to all the information they need, from room details to local recommendations, right at their fingertips.',
  },
  {
    step: 'Step 3',
    title: 'Analyze & Improve',
    image: './img/Grafik_Mockups_Statistic_2.webp',
    description: 'Use detailed statistics and guest feedback from the Guest Pages to continuously enhance your services, increase guest satisfaction, and boost your hotel`s efficiency and revenue.',
  },
];

const BookingPage1 = () => {

  useEffect(() => {
    if (window.fbq) {
      window.fbq('track', 'BookCallPageView');
    }
  }, []);


  return (
    <div className="landing-page">
      <Navigation2 
      actionLink="https://bonusclub.link"
      actionLinkName="Start For free"
      />
      <CalendlyPage />
      <ActionPage />
      <TestimonialVideo />
      <FAQPage />
      <Footer />
      <VideoCTA />
      <ExitPopup />
    </div>
  );
};

export default BookingPage1;
